export function login(token) {
    return dispath => {
        dispath({
            type: "LOGIN",
            payload: token
        });
    }
}

export function logout() {
    console.log("Logging out");
    return dispath => {
        dispath({
            type: "LOGOUT",
            payload: ""
        });
    };
}

export function setOfice(data) {
    return dispath => {
        dispath({
            type: "OFICE",
            payload: data
        });
    }
}

export function setProjectCode(data) {
    return dispath => {
        dispath({
            type: "PROJECTCODE",
            payload: data
        });
    }
}

export function setProject(data) {
    return dispath => {
        dispath({
            type: "PROJECT",
            payload: data
        });
    }
}

export function setToken(data) {
    return dispath => {
        dispath({
            type: "SETTOKEN",
            payload: data
        });
    }
}