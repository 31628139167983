import React, { Component, Fragment } from "react";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import config from "../../../config.json";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import dayjs from 'dayjs'
import { setToken } from "../../../actions/authActions";
import { Button, Form, FormGroup, Label, Row, Col, CardHeader, Card, CardBody, Input, Modal, ModalHeader, ModalBody, ModalFooter,} from "reactstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FaEye, FaEyeSlash, FaEnvelope} from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation} from 'react-i18next';
import './loginStyle.css' 


class LoginUserPass extends Component{
    constructor(props) {
        super(props);
        this.state= {
          qurCode : "",
          userValues: {
            username: "",
            pass: "",
            code: ""
          },
          secret: "*",
          userId: 0,
          statusMessage: "",
          modalQr: false,
          passwordType: "password",
          emailFocus: false,
          passFocus: false
        }
        
        this.activate2FA = this.activate2FA.bind(this);
        this.verifyCode = this.verifyCode.bind(this);
        this.toggleModalQr = this.toggleModalQr.bind(this);
        this.verifyCode = this.verifyCode.bind(this);
        this.togglePassword = this.togglePassword.bind(this);
        this.OnFocusEmail = this.OnFocusEmail.bind(this);
        this.onBlurEmail = this.onBlurEmail.bind(this);
        this.onPassFocus = this.onPassFocus.bind(this);
        this.onPassBlur = this.onPassBlur.bind(this);
    }

    componentDidMount() 
    {

	  }

    togglePassword(){
      if(this.state.passwordType === "password")
        this.setState({passwordType:"text"});
      else
        this.setState({passwordType:"password"});
    }

    toggleModalQr()
    {
      this.setState({modalQr: !this.state.modalQr});
    }

    handleChange = (e) => {
      const { name, value } = e.target;
      this.setState({
        userValues: {
          ...this.state.userValues,
          [name]: value,
        },
      });
      this.setState({statusMessage:""});
    };

  displayOptions(user)
  {
    this.props.login(user);
    var data = {id:3,url:""};
    this.props.history.push({
        pathname: '/',
        query:data,
      })    
  }

  verifyCodeNocode()
  {
		const bod = {                        
      UserId: this.state.userId,
      Name: "",
      Email: "",
      Pass: "OK",
      SecretCode: ""
    };

		const values = JSON.stringify(bod);
    
    const options = {
      method: 'POST',
      headers: { 'Access-Control-Allow-Origin': true,'Content-Type': 'application/json' },
      body: values,
    };

    fetch(config.API_TIJAX_LOGIN, options)
    .then(r => {
      r.json().then(resultado => {
        if(resultado.idUsuario > 0)
          this.displayOptions(resultado);
        else
          this.setState({statusMessage:"Código no es válido"});
      })
    })
  }


  verifyCode()
  {
		const bod = {                        
      UserId: this.state.userId,
      Name: "",
      Email: "",
      Pass: "",
      SecretCode: this.state.userValues.code
    };

		const values = JSON.stringify(bod);
    
    const options = {
      method: 'POST',
      headers: { 'Access-Control-Allow-Origin': true,'Content-Type': 'application/json' },
      body: values,
    };

    fetch(config.API_TIJAX_LOGIN, options)
    .then(r => {
      r.json().then(resultado => {
        if(resultado.idUsuario > 0)
          this.displayOptions(resultado);
        else
          this.setState({statusMessage:"Código no es válido"});
      })
    })
  }



  activate2FA(userId)
  {
		const bod = {                        
      UserId: userId,
      Name: "",
      Email: "",
      Pass: ""};

		const values = JSON.stringify(bod);

    const options = {
      method: 'PATCH',
      headers: { 'Access-Control-Allow-Origin': true,'Content-Type': 'application/json' },
      body: values,
    };

    fetch(config.API_TIJAX_LOGIN, options)
      .then(r => {
        r.json().then(code => {
          this.setState({qurCode: code.image});
          this.toggleModalQr();
        })
      })
  }  


  LoginWT = () => {

		const bod = {                        
      UserId: 0,
      Name: "",
      Email: this.state.userValues.username,
      Pass: this.state.userValues.pass
    };
    const values = JSON.stringify(bod);

    
    const options = {
      method: 'PUT',
      headers: { 'Access-Control-Allow-Origin': true,'Content-Type': 'application/json' },
      body: values,
    };
    fetch(config.API_TIJAX_LOGIN, options)
      .then(r => {
        r.json().then(user => {
          if(user.userId > 0)
          {
            this.setState({userId: user.userId});
            if(user.hasSecretKey === 'N')
            {
              this.activate2FA(user.userId);
              document.getElementById("vLoginButton").style.display = "none";
              document.getElementById("vVerifyCode").style.display = "block";
              document.getElementById("vcode").style.display = "block";
            }
            else if(user.hasSecretKey === 'S')
            {
              if(user.getAuthenticatorCode === 'S')
              {
                document.getElementById("vLoginButton").style.display = "none";
                document.getElementById("vVerifyCode").style.display = "block";
                document.getElementById("vcode").style.display = "block";
              }
              else{
                this.verifyCodeNocode();
              }
              //this.props.login(user);
            }
          }
          else
          {
            this.setState({statusMessage: "Usuario o contraseña son incorrectos"});
          }
        });
      })
  };


  OnFocusEmail()
  {
    this.setState({emailFocus: true});
  }

  onBlurEmail()
  {
    this.setState({emailFocus: false});
  }

  onPassFocus()
  {
    this.setState({passFocus: true});
  }

  onPassBlur()
  {
    this.setState({passFocus: false});
  }

    render() {

      let emailBorderColor = this.state.emailFocus ? '#0092D1': '#d0e1f3';
      let passBorderColor = this.state.passFocus ? '#0092D1': '#d0e1f3';
      let emailBackColor = "#d0e1f3";

        return (
            <Fragment>
            <TransitionGroup>
              <CSSTransition
                component="div"
                classNames="TabsAnimation"
                appear={true}
                timeout={1500}
                enter={false}
                exit={false}
              >
                <div>
                  <Row md={8}>
                    <Col>
                    <div style={{background: '#E8F0FE', width: '350px', height: '40px', paddingTop: '2px', borderRadius: '5px', borderColor: emailBorderColor, borderStyle: 'solid', borderWidth: '1px', paddingBottom: '1px'}}>
                      <div className="mb-2" style={{width:'300px', float:'left', background: '#E8F0FE', height: '25px'}}>
                        <input
                            type="text"
                            className="form-control"
                            id="username"
                            aria-describedby="emailHelp"
                            placeholder={this.props.t('Email')}
                            name="username"
                            value={this.state.userValues.username}
                            ref="username"
                            onChange={this.handleChange}
                            style={{backgroundColor: '#E8F0FE', width:'280px', borderColor:'#E8F0FE', boxShadow:'none', height: '25px'}}
                            onFocus={this.OnFocusEmail}
                            onBlur={this.onBlurEmail}  
                        ></input>
                      </div> 
                      <div style={{width:'40px', float:'left', background: '#E8F0FE', color:'#000'}}>
                          <button className="btn btn-outline-primary" style={{width:'40px', borderColor:'#E8F0FE', color:'#000'}} onClick={this.togglePassword}>
                            <FaEnvelope size={16} />
                            </button>
                          </div>                      
                      </div>
                      <div style={{height:'5px'}}>

                      </div>

                        <div style={{background: '#E8F0FE', width: '350px', height: '40px', paddingTop: '2px', borderRadius: '5px', borderColor: passBorderColor, borderStyle: 'solid', borderWidth: '1px'}}>
                          <div className="mb-2" style={{width:'300px', float:'left', background: '#E8F0FE'}}>
                            <input
                              type={this.state.passwordType}
                              className="form-control"
                              id="pass"
                              aria-describedby="emailHelp"
                              placeholder={this.props.t('Password')}
                              name="pass"
                              value={this.state.userValues.pass}
                              ref="pass"
                              onChange={this.handleChange}
                              style={{background: '#E8F0FE', width:'280px', borderColor:'#E8F0FE', boxShadow:'none'}}
                              onFocus={this.onPassFocus}
                              onBlur={this.onPassBlur}
                            ></input>
                          </div>
                          <div style={{width:'40px', float:'left', background: '#E8F0FE', color:'#000'}}>
                          <button className="btn btn-outline-primary" style={{width:'40px', borderColor:'#E8F0FE', color:'#000'}} onClick={this.togglePassword}>
                           {this.state.passwordType === "password" ? <FaEye size={16} /> : <FaEyeSlash size={16} />}
                            </button>
                          </div>
                        </div> 
                        

                      <div className="mb-2" id="vcode" style={{display:"none"}}>
                          <input
                              type="text"
                              className="form-control"
                              id="code"
                              aria-describedby="emailHelp"
                              placeholder={this.props.t('Code')}
                              name="code"
                              value={this.state.userValues.code}
                              ref="code"
                              onChange={this.handleChange}
                              style={{borderColor:'#E8F0FE', boxShadow:'none', backgroundColor: '#E8F0FE'}}
                          >
                          </input>
                      </div>
                      <div className="mb-2" id="vLoginButton" >
                        <Button onClick={this.LoginWT} style={{backgroundColor:'#1c73e8', width:'100%'}}>Iniciar Sesión</Button>
                        <div style={{color: '#208888', width:'100%', textAlign:'center'}}>¿Olvidó su Contraseña?</div>
                      </div>
                      <div className="mb-2" id="vVerifyCode" style={{display:"none"}}>
                        <Button onClick={this.verifyCode} style={{backgroundColor:'#1c73e8', width:'100%'}}>Vericar código</Button>
                      </div>
                    </Col>
                  </Row>
                    <div>
                      {this.state.statusMessage}
                    </div>
                    <Modal
                      isOpen = {this.state.modalQr}
                      toggle={this.toggleModalQr}
                      className={this.props.className + " modal-sm"}
                    >
                      <ModalHeader toggle={this.toggleModalQr}>
                      {this.props.t('Activar Segundo Factor de Autenticación')}
                      </ModalHeader>
                      <ModalBody>
                        <img src={this.state.qurCode} />
                      </ModalBody>
                    </Modal>
                </div>
                </CSSTransition>
        </TransitionGroup>
      </Fragment>                    
        );
    }

    miCustomAxios()
    {
      try
      {
        const headers= {
          "Access-Control-Allow-Origin": true,
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.auth.accessToken,
        }
    
        const refresh = { 'AccessToken': this.props.auth.accessToken, "RefreshToken": this.props.auth.refreshToken, "error": "" }
        const vRefresh = JSON.stringify(refresh);
    
        const customAxios = axios.create();
    
        customAxios.interceptors.request.use(async req => {
            const user = jwt_decode(this.props.auth.accessToken);
            const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    
            if(!isExpired) return req;
            
            let respuesta = await axios.put(config.GOOGLE_AUTH_CALLBACK_URL,
                    vRefresh,
                    { headers });
    
            this.props.setToken(respuesta.data);
            req.headers.Authorization = `Bearer ${respuesta.data.accessToken}`
            return req;
        })
    
        return customAxios;
      }
      catch (error) {
          console.log(error);
      }
    }    
}

const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = (dispatch) => {
      return {
        setToken: (token) => {
          dispatch(setToken(token));
        }
      }
    };
  
  export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(LoginUserPass)));
  