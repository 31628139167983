import { createStore, combineReducers, applyMiddleware} from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import reducers from "../reducers";
import auth from "../reducers/authReducer"

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers,
      auth
    }),
    {},
    applyMiddleware(createLogger(), thunk)
  );
}
